import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// import '../OrganizationDetailBox.css';
import axios from 'axios';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import {API_URL} from '../constants'
import {timeConverter} from '../helpers'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const dispositions = [
  {
    value: 'Pending Review',
  },
  {
    value: 'Declined',
  },
  {
    value: 'Accepted',
  },
  {
    value: 'Funding in installments',
  },
  {
    value: 'Tabled',
  },
];

const counties = [
  {
    value: 'Grayson County'
  },
  {
    value: 'Mayes County'
  }
];
 
const programAreas = [
  {
    value: 'General Operating',
  },
  {
    value: 'Project Support',
  },
  {
    value: 'Capitol Fund Support',
  },
  {
    value: 'Challenge Grant',
  },
]

class GrantTextField extends React.Component {
  constructor(props){
    super(props)
    
    this.state = {
      date_paid: this.props.grant.date_paid,
      date_request: this.props.grant.date_request,
      disposition: this.props.grant.disposition,
      entry_date: this.props.grant.entry_date,
      grant_amount: this.props.grant.grant_amount,
      id: this.props.grant.id,
      organization_id: this.props.grant.organization_id,
      other_sources: this.props.grant.other_sources,
      previous_grants: this.props.grant.previous_grants,
      program_area: this.props.grant.program_area,
      project_description: this.props.grant.project_description,
      project_end: this.props.grant.project_end,
      project_start: this.props.grant.project_start,
      requested_amount: this.props.grant.requested_amount,
      submitter_ip: this.props.grant.submitter_ip,
      title: this.props.grant.title,
      total_payed: this.props.total_payed,
      installment_total: this.props.installment_total,
      individual_dispersment_amount: this.props.individual_dispersment_amount,
      dispersments_to_date: this.props.dispersments_to_date,
      amount_dispersed_to_date: this.props.amount_dispersed_to_date,
      contact_name: this.props.grant.contact_name,
      contact_title: this.props.grant.contact_title,
      contact_fax: this.props.grant.contact_fax,
      contact_number: this.props.grant.contact_number,
      contact_email: this.props.grant.contact_email,
      request_contact_name: this.props.grant.request_contact_name,
      request_contact_title: this.props.grant.request_contact_title,
      request_mailing_address: this.props.grant.request_mailing_address,
      request_contact_number: this.props.grant.request_contact_number,
      request_email: this.props.grant.request_email,
    };
    
    this.saveData = this.saveData.bind(this);
    
  }
  
  componentDidMount(){
    // this.org = JSON.parse(this.props.organization);
    console.log("this.props edit", this.props)
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  
  saveData = () => {
    axios.put(`${API_URL}/grant/${this.state.id}`, this.state)
          .then((res) => console.log(res))
          .then(() =>{
            this.props.handleStatusMessage(true)
          })
          .catch((err) => {
            console.log(err)
            this.props.handleStatusMessage(false)
          })
          .finally(() => {
            this.props.toggleEdit();
          })
  }
  render() {
    const { classes } = this.props;
    let smallView = this.props.smallView;
    
    return (
      <Paper className={classes.root}>
      <Typography variant="h2">Edit Grant</Typography>
      <Divider />
      <form className={classes.container} noValidate autoComplete="off">
      <Button  variant="contained" color="primary" className={classes.button} onClick={this.saveData}>Save</Button>
      <Button variant="contained" onClick={this.props.toggleEdit}>Cancel</Button>
      <Grid container spacing={24}>
      <Grid item xs={12} sm={smallView} xl={smallView} zeroMinWidth>
      <List>     
      <ListItem><Typography variant="h4">Contact Details</Typography></ListItem>
          <ListItem>
          <TextField
          id="outlined-contact_name"
          label="Name"
          defaultValue={this.state.contact_name}
          onChange={this.handleChange('contact_name')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-contact_title"
          label="Title"
          defaultValue={this.state.contact_title}
          onChange={this.handleChange('contact_title')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-contact_number"
          label="Phone Number"
          defaultValue={this.state.contact_number}
          onChange={this.handleChange('contact_number')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-contact_fax"
          label="Contact Fax"
          defaultValue={this.state.contact_fax}
          onChange={this.handleChange('contact_fax')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-contact_email"
          label="Contact Email"
          defaultValue={this.state.contact_email}
          onChange={this.handleChange('contact_email')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          
          </ListItem>
          <ListItem><Typography variant="h4">Requested Contact Details</Typography></ListItem>
          <ListItem>
          <TextField
          id="outlined-request_contact_name"
          label="Name"
          defaultValue={this.state.request_contact_name}
          onChange={this.handleChange('request_contact_name')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-request_contact_title"
          label="Title"
          defaultValue={this.state.request_contact_title}
          onChange={this.handleChange('request_contact_title')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-request_contact_number"
          label="Phone Number"
          defaultValue={this.state.request_contact_number}
          onChange={this.handleChange('request_contact_number')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          <TextField
          id="outlined-request_contact_email"
          label="Email"
          defaultValue={this.state.request_contact_email}
          onChange={this.handleChange('request_contact_email')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          
          </ListItem>  
        <ListItem><Typography variant="h4">Details</Typography></ListItem>
        
        
        <ListItem><TextField
      id="outlined-select-disposition"
      select
      label="Disposition"
      className={classes.textField}
      value={this.state.disposition}
      onChange={this.handleChange('disposition')}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="outlined"
      >
      {dispositions.map(option => (
        <MenuItem key={option.value} value={option.value}>
        {option.value}
        </MenuItem>
        ))}
        </TextField>
        </ListItem>
        <TextField
        id="outlined-grant_amount"
        label="Grant Amount"
        className={classes.textField}
        value={this.state.grant_amount}
        defaultValue={this.state.grant_amount}
        onChange={this.handleChange('grant_amount')}
        margin="normal"
        variant="outlined"
        />
        <TextField
        id="outlined-requested_amount"
        label="Requested Amount"
        className={classes.textField}
        value={this.state.requested_amount}
        defaultValue={this.state.requested_amount}
        onChange={this.handleChange('requested_amount')}
        margin="normal"
        variant="outlined"
        />
         <TextField
        id="outlined-total_payed"
        label="Total Payed"
        className={classes.textField}
        value={this.state.total_payed}
        defaultValue={this.state.total_payed}
        onChange={this.handleChange('total_payed')}
        margin="normal"
        variant="outlined"
        />
        <TextField
        id="outlined-date_paid"
        label="Date Payed"
        className={classes.textField}
        value={timeConverter(this.state.date_paid)}
        defaultValue={timeConverter(this.state.date_paid)}
        onChange={this.handleChange('date_paid')}
        margin="normal"
        variant="outlined"
        />
         <TextField
        id="outlined-date_requested"
        label="Date Submitted"
        className={classes.textField}
        value={timeConverter(this.state.entry_date)}
        defaultValue={timeConverter(this.state.entry_date)}
        onChange={this.handleChange('entry_date')}
        margin="normal"
        variant="outlined"
        />

         <TextField
      id="outlined-select-program_area"
      select
      label="Program Area"
      fullWidth
      className={classes.textField}
      value={this.state.program_area}
      onChange={this.handleChange('program_area')}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="outlined"
      >
      {programAreas.map(option => (
        <MenuItem key={option.value} value={option.value}>
        {option.value}
        </MenuItem>
        ))}
        </TextField>

        <TextField
        id="outlined-project_start"
        label="Project Start"
        className={classes.textField}
        value={timeConverter(this.state.project_start)}
        defaultValue={timeConverter(this.state.project_start)}
        onChange={this.handleChange('project_start')}
        margin="normal"
        variant="outlined"
        />

         <TextField
        id="outlined-project_end"
        label="Project End"
        className={classes.textField}
        value={timeConverter(this.state.project_end)}
        defaultValue={timeConverter(this.state.project_end)}
        onChange={this.handleChange('project_end')}
        margin="normal"
        variant="outlined"
        />
          <TextField
            id="outlined-multiline-flexible"
            label="Project Description"
            multiline
            fullWidth
            rows="5"
            value={this.state.project_description}
            onChange={this.handleChange('project_description')}
            className={classes.textField}
            margin="normal"
            
            variant="outlined"
            />
           
            <TextField
            id="outlined-multiline-flexible"
            label="Previous Grants"
            multiline
            fullWidth
            rows="5"
            value={this.state.previous_grants}
            onChange={this.handleChange('previous_grants')}
            className={classes.textField}
            margin="normal"
            
            variant="outlined"
            />

               <TextField
            id="outlined-multiline-flexible"
            label="Other Sources"
            multiline
            fullWidth
            rows="5"
            value={this.state.other_sources}
            onChange={this.handleChange('other_sources')}
            className={classes.textField}
            margin="normal"
            
            variant="outlined"
            />

        {/* Render if User has installments selected */}
        {this.state.disposition.includes("nstallments") ? 
        <div>
          <TextField
        id="outlined-installment_total"
        label="Number of Installments"
        className={classes.textField}
        value={this.state.installment_total}
        defaultValue={this.state.installment_total}
        onChange={this.handleChange('installment_total')}
        margin="normal"
        variant="outlined"
        />

        <TextField
        id="outlined-individual_dispersment_amount"
        label="Amount Per Dispersement"
        className={classes.textField}
        value={this.state.individual_dispersment_amount}
        defaultValue={this.state.individual_dispersment_amount}
        onChange={this.handleChange('individual_dispersment_amount')}
        margin="normal"
        variant="outlined"
        />

        <TextField
        id="outlined-dispersments_to_date"
        label="Dispersments to Date"
        className={classes.textField}
        value={this.state.dispersments_to_date}
        defaultValue={this.state.dispersments_to_date}
        onChange={this.handleChange('dispersments_to_date')}
        margin="normal"
        variant="outlined"
        />

        <TextField
        id="outlined-amount_dispersed_to_date"
        label="Amount Dispersed To Date"
        className={classes.textField}
        value={this.state.amount_dispersed_to_date}
        defaultValue={this.state.amount_dispersed_to_date}
        onChange={this.handleChange('amount_dispersed_to_date')}
        margin="normal"
        variant="outlined"
        />
        </div>
        : ''}
      
      </List>
      
      </Grid>
      </Grid>
      </form>
      </Paper>
      );
    }
  }
  
  
  
  export default withStyles(styles)(GrantTextField);