// src/ui/theme/index.js

import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette : {
    primary: { main: '#0D283C' },
    secondary: { main: '#E5BF78' }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontSize:'14',
    fontFamily: [
      'source-serif-pro',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
    ].join(','),
    h2: {
      fontSize: "1.6rem",
      fontWeight: 700 
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 700 
      
    },
    h6:{
      fontSize: "1.1rem",
    }
},
overrides: {
  Link: { // Name of the component ⚛️ / style sheet
    root: { // Name of the rule
      color: '#0D283C', // Some CSS
    },
  },
},
}
export const primaryColor = '#0D283C';
export const secondaryColor = '#E5BF78';
const themeName = 'Elephant Harvest Gold Indian Gharial';

export default createMuiTheme(theme)