const getURL = function(){  
  if(process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV !== "staging"){
    return 'https://app.mayorfoundation.org'
  } else if (process.env.NODE_ENV === 'staging' || process.env.REACT_APP_ENV === "staging"){
    return 'https://ogma-staging.herokuapp.com'
  } else{
    return 'http://localhost:3000'
  }

};

export const formatNumber = (num) =>{
  if(!String(num).includes(",") && !String(num).includes("$")){
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    
      return formatter.format(num)
  }else{
    return num
  }
}

export const ROOT_URL = getURL();
export const API_URL = `${ROOT_URL}/api`;

