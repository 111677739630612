import React, { Component } from 'react'
import axios from 'axios';
import {API_URL} from '../constants'
import OrganizationDetailBox from '../OrganizationDetailBox'
import GrantTable from '../GrantTable'
import DetailGrantBox from '../DetailGrantBox'
import Grid from '@material-ui/core/Grid'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export const SplitViewContext = React.createContext({
  splitView: false,
});
export class IndividualOrganizationView extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      organization: [],
      grantView: false,
      splitView: false,
      grant: false,
      printView: false,
      grantID: null
    }

    this.orgID = this.props.location.pathname.split("/").pop();
    this.openSplitView = this.openSplitView.bind(this)
    this.closeSplitView = this.closeSplitView.bind(this)
    this.togglePrintView = this.togglePrintView.bind(this)
 }
  
  
  toggleSplitView = () =>{
    this.setState({splitView: !this.splitView})
  }
  togglePrintView = () => {
    this.setState({printView: !this.printView})
  }
  componentDidMount(){
    this.getOrganization();
  }

  didEdit = (id) => {
    this.setState({didEdit: !this.state.didEdit})
    this.getOrganization();
    this.getGrant(this.state.grantID);
  }

  getGrant = (id) => {
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    axios.get(`${API_URL}/grant/id/${id}`, { headers })
      .then(res => {
          return res.data})
      .then(obj => {
        this.setState({grant: obj})
      })    
      .then(x =>{
        this.setState({loading: false});
      }).catch(error => {console.log("ERROR fetching grants " + error);})
  }
  
  openSplitView(grant){
    this.setState({splitView: true,
                    grant: grant,
                    grantID: grant.id})
  }

  closeSplitView(){
    this.setState({splitView: false})
  }

  getOrganization = () => {
    // Get the passwords and store them in state
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    axios.get(`${API_URL}/organization/${this.orgID}`, { headers })
      .then(res => {
        this.org = res.data
        this.setState({loading: false})
      })
    .catch(error => {console.log("ERROR fetching " + error);})
  }

  render(){
    let small = this.state.printView ? 8 : 12;
    
    
    if(this.state.loading){
      return(<p>Loading...</p>)
    }else if(this.state.splitView){
      return(
        <React.Fragment>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}><OrganizationDetailBox splitView={this.state.splitView} {...this.org} {...this.props} didEdit={this.didEdit}/></Grid>
            <Grid item xs={12} sm={8}><DetailGrantBox orgID={this.orgID} grant={this.state.grant} closeSplitView={this.closeSplitView} {...this.props} didEdit={this.didEdit} togglePrintView={this.togglePrintView} printView={this.state.printView}/></Grid>
          </Grid>
          {this.state.printView ? '' : <GrantTable orgID={this.orgID} openSplitView={this.openSplitView}  {...this.props} />}
        </React.Fragment>
      )
    }else{
      return(
        <React.Fragment>
          <OrganizationDetailBox splitView={this.state.splitView} {...this.org} {...this.props } didEdit={this.didEdit}/>
          <GrantTable orgID={this.orgID} openSplitView={this.openSplitView}  {...this.props} />
        </React.Fragment>
      )
    }
  }
 
}

export default IndividualOrganizationView

