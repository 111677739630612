import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import {API_URL, formatNumber} from '../constants'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class GrantTable extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      grantsArray: [],
    }

    this.deleteGrants = this.deleteGrants.bind(this);
    
  }
  
  componentDidMount() {
    this.getGrants();
  }
  listOrgs(array){
    this.setState({loading: false});
  }

  timeConverter(timestamp){
  
    return timestamp ? timestamp.split('T')[0] : ''   
  }

  passSplitView(grant){
    this.props.openSplitView(grant);
  }

  deleteGrants(deletedGrants){
    let {data} = deletedGrants;
    let array = [];
    let query = "?";
    data.map((grant) =>{
      let {dataIndex} = grant;
      let specificGrant = this.state.grantsArray[dataIndex];
      array.push(specificGrant.pop());
      
    })

    
    array.map((id) => {
      console.log(id);
      query += `id=${id}`
    })

    console.log(query);

    axios.delete(`${API_URL}/grant/batch${query}`).catch((err)=> console.log(err))
  }



  getGrants(){
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    axios.get(`${API_URL}/grant/org/${this.props.orgID}`, { headers })
      .then(res => {
          return res.data})
      .then(arr => {
        let grants = arr.map((grant) =>{
          let array = [];
          //[name, id, /county/, ]
          
          array.push((<a href="#" className="grant" data-grant-id={grant.id}
                      onClick={() => this.passSplitView(grant)}
          >{grant.title}</a>));
          array.push(grant.title);
          array.push(grant.disposition);
          array.push(this.timeConverter(grant.date_paid))
          array.push(this.timeConverter(grant.project_start))
          array.push(this.timeConverter(grant.project_end))
          array.push(grant.program_area)
          array.push(formatNumber(grant.requested_amount))
          array.push(formatNumber(grant.grant_amount))
          array.push(this.timeConverter(grant.entry_date))
          array.push(grant.id) 
          return array;
        });

        this.setState({grantsArray: grants})
        
      })    
      .then(x =>{
        this.setState({loading: false});
      }).catch(error => {console.log("ERROR fetching grants " + error);})
  }

  render(){

    const columns = [
      {
       name: "Title",
       options: {
        filter: false,
        sort: true,
       }
      },
      {
        name: "Title-Plain",
        options: {
         filter: false,
         sort: true,
         display:false,
        }
       },
      {
        name: "Disposition",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Date Paid",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Project Start",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Project End",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Program Area",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Requested Amount",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Grant Amount",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Date Submitted",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "ID",
        options: {
         filter: false,
         sort:false,
         display:false
        }
       }
     ];
     
    
     
     const options = {
       filterType: 'checkbox',
       print: false,
        download: false,
        onRowsDelete: this.deleteGrants,
        selectableRows: false,
     };
     
    
    if(this.state.loading){
      return(<p>Loading Grant Table...</p>)
    }else{
      return(
        <MUIDataTable
        title={"Grant List"}
        data={this.state.grantsArray}
        columns={columns}
        options={options}
      />
      )
    }

  }
}

export default withStyles(styles)(GrantTable)

