import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import axios from 'axios';
import { Redirect, Router, Route, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import Edit from '@material-ui/icons/Edit'
import CallMerge from '@material-ui/icons/CallMerge'
import Icon from '@material-ui/core/Icon';
import {API_URL} from '../constants'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    marginTop: 20,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
  
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  titleDivider: {
    margin: 20
  },
  lineBreak:{
    whiteSpace: "pre-line"
  },
  itemTitle:{
    marginRight: 10
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '15%',
    left: '15%',
  },
  search:{
    height: "1rem",
    marginTop: "1rem"
  }
 
});

class MergeView extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       loading: true,
       organizationsArray: [],
       filteredOrgs: [],
       childOrg: '',
       childID: '',
       name: '',
       county: 'Any',
       isModalOpen: false,
       redirect: false,
    }

    this.getOrganizations = this.getOrganizations.bind(this)
    this.updateOrgArray = this.updateOrgArray.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.mergeOrgs = this.mergeOrgs.bind(this)
  }

  componentDidMount(){
    this.getOrganizations()
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  mergeOrgs = () => {
    axios.put(`${API_URL}/organization${this.props.match.url}/${this.state.childID}`)
          .then((res) => console.log(res))
          .then(this.handleClose)
          .then(()=>{
            this.setState({redirect: true})
          })
          .catch((err) => console.log(err))
  }

  getOrganizations = () => {
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    axios.get(`${API_URL}/organization`, { headers })
      .then(res => {
          return res.data})
      .then(arr => {
        let organizations = arr.map((org) =>{
          let array = [];
          array.push(org.name)
          array.push(org.county_id)
          array.push(org.id);
          return array;
        });

        this.setState({organizationsArray: organizations})
        
      })    
      .then(x =>{
        this.setState({loading: false});
      }).catch(error => {console.log("ERROR fetching orgs " + error);})
  }

  handleOpen = (child) => {

    this.setState({isModalOpen: true,
                    childOrg: child[0],
                     childID: child[2]})
  }

  updateOrgArray = (event) => {
    event.preventDefault();
    const search = this.state.name.toLowerCase();
    let arr = this.state.organizationsArray.filter((item) => {
      let organization = item[0]
      organization = organization.toLowerCase();

      if(this.state.county !== "Any"){
        return organization.includes(search) && (this.state.county == item[1])
      }else{
        return organization.includes(search);
      }
    });

    this.setState({filteredOrgs: arr})
  }

  handleClose = () => {
    this.setState({isModalOpen: false,
      childOrg: '',
      childID: ''})
  }

  
  
  render() {
    const {classes} = this.props;
    const {organizationName} = this.props.location.state || "Organization";
    return (
      <div>
        {
          this.state.redirect ? <Redirect to="/app" /> : ''
        }
        <Paper>
          <Typography variant="h2" component="h2">Merge Organizations</Typography>
          <Divider></Divider>
          <Typography>Select which organization you want to merge their grants into {organizationName}</Typography>
          {this.state.loading ? (<p>Loading...</p>) : 
          (<form className={this.props.classes.container} noValidate autoComplete="off" onSubmit={this.updateOrgArray}>
            <TextField
              id="outlined-name"
              label="Organization Name"
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"/>
              <TextField
                id="outlined-select-county"
                select
                label="Select"
                className={classes.textField}
                value={this.state.county}
                onChange={this.handleChange('county')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                helperText="Select county"
                margin="normal"
                variant="outlined"
              >
                  <MenuItem key={"Grayson County"} value={"Grayson County"}> {"Grayson County"}</MenuItem>
                  <MenuItem key={"Mayes County"} value={"Mayes County"}> {"Mayes County"}</MenuItem>
                  <MenuItem key={"Any"} value={"Any"}> {"Any"}</MenuItem>                      
        </TextField>
        <Button variant="contained" color="primary" className={classes.search} onClick={this.updateOrgArray}>Search</Button>
          </form>
          )}
          
          {this.state.filteredOrgs.length ? (<List>{this.state.filteredOrgs.map((org) =>(
            <ListItem key={org[0]}><Button 
            variant="outlined" color="primary" className={classes.button} 
            onClick={() => this.handleOpen(org)}>{org[0]}</Button></ListItem>
          ))}</List>) 
          : ''}

          {this.state.isModalOpen ? (<Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.isModalOpen}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <Typography variant="h4" id="modal-title">
              Are you sure you want to merge {this.state.childOrg} into {organizationName}?
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              Warning: This will transfer all of the grant data from {this.state.childOrg} to {organizationName} and delete {this.state.childOrg}'s contact information. <b>This is not reversible</b>. 
            </Typography>
            <Button variant="contained" color="primary" onClick={this.mergeOrgs}>Yes</Button>
            <Button variant="contained" color="secondary"onClick={this.handleClose}>Cancel</Button>
            
          </div>
        </Modal>) :''}

        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(MergeView);