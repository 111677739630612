import React from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import App from './App';
import Home from './Home/Home';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';
import Profile from './Profile/Profile';
import OrganizationView from '../components/OrganizationView';
import IndividualOrganizationView from './IndividualOrganizationView'
import UploadPage from './UploadPage'
import theme from './theme'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import MergeView from './MergeView'


const auth = new Auth();

const handleAuthentication = ({location}) => {
if (/access_token|id_token|error/.test(location.hash)) {
auth.handleAuthentication();
}
}

export const makeMainRoutes = () => {
return (
  <Router history={history}>
    <div>
    <MuiThemeProvider theme={theme}>
      <Route path="/" render={(props) => <App auth={auth} {...props} />} />
      <Route path="/home" render={(props) => <Home auth={auth} {...props} />} />
      <Route path="/upload/:id" render={(props) => (
        <UploadPage  {...props} />
        )} />
      <Route path="/organization/:id" render={(props) => (
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <IndividualOrganizationView auth={auth} {...props} />
            ))} />
      <Route path="/merge/:id" render={(props) => (
      !auth.isAuthenticated() ? (
        <Redirect to="/home"/>
      ) : (
        <MergeView auth={auth} {...props} />
      ))} />
      <Route path="/app" render={(props) => (
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <OrganizationView auth={auth} {...props} />
            ))} />
      <Route path="/profile" render={(props) => (
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <Profile auth={auth} {...props} />
            )
        )} />
      <Route path="/callback" render={(props) => {
        handleAuthentication(props);
        return <Callback {...props} /> 
      }}/>
    </MuiThemeProvider>
    </div>
  </Router>
);
}
