import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const styles = theme => ({
  itemTitle:{
    marginRight: 10
  }
  
});

function Installments(props) {

  const {installment_total, individual_dispersment_amount, dispersments_to_date, amount_dispersed_to_date, grant,classes} = props;
  let grant_amount = grant.grant_amount ? grant.grant_amount.split("").filter((x) => { return x !== "$" && x !== ","}).join("") : installment_total * individual_dispersment_amount;
  return (
    <div>
      <Divider></Divider>
      <List>
        <ListItem><Typography className={classes.itemTitle} variant="h6">Total No. of Installments:</Typography> {installment_total}</ListItem>
        <ListItem><Typography className={classes.itemTitle} variant="h6">Amount per Disbursement:</Typography> {individual_dispersment_amount}</ListItem>
        <ListItem><Typography className={classes.itemTitle} variant="h6">Amount of Installments Dispersed to Date:</Typography> {dispersments_to_date}</ListItem>
        <ListItem><Typography className={classes.itemTitle} variant="h6">Amount Dispersed:</Typography> {amount_dispersed_to_date}</ListItem>
        <ListItem><Typography className={classes.itemTitle} variant="h6">Amount remaining to be dispersed:</Typography> {Number(grant_amount) - amount_dispersed_to_date}</ListItem>
      </List>
    </div>
  )
}

export default withStyles(styles)(Installments);

