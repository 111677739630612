import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import {API_URL} from '../constants'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class OrganizationTable extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      organizationsArray: []
    }
  }
  
  componentDidMount() {
    this.getOrganizations(this.props.orgFilterType);
  }

  componentDidUpdate(previousProps){
    if (this.props.orgFilterType !== previousProps.orgFilterType){
      this.getOrganizations(this.props.orgFilterType)
    }
  }
  // listOrgs(array){ COMMENT TEST
  //   this.setState({loading: false});
  // }

  selectNone = () =>{
    return false
  }

  getOrganizations = (filterType = "All") => {
    // Get the passwords and store them in state
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    const filterURL = filterType === "All" ? '' : ("/filter/" + filterType)
    axios.get(`${API_URL}/organization${filterURL}`, { headers })
      .then(res => {
          return res.data})
      .then(arr => {
        let organizations = arr.map((org) =>{
          let array = [];
          //[name, id, /county/, ]
          let html = {

          }
          array.push((<a href={`/organization/${org.id}`}>{org.name}</a>));
          array.push(org.name)
          array.push(org.county_id);
          array.push(org.id);
          array.push(org.tax_status);
          array.push(org.grants_accepted);
          array.push(org.grants_pending);
          array.push(org.grants_tabled);
          array.push(org.grants_declined);

          if(org.id == "199"){
            console.log(JSON.stringify(org))
          }
          return array;
        });

        this.setState({organizationsArray: organizations})
        
      })    
      .then(x =>{
        this.setState({loading: false});
      }).catch(error => {console.log("ERROR fetching orgs " + error);})
  }

  render(){

    const columns = [
      {
       name: "Company",
       options: {
        filter: false,
        sort: true,
       }
      },
      {
        name: "Company-Plain",
        options: {
         filter: false,
         sort: true,
         display: false,
        }
       },
      {
        name: "County",
        options: {
         filter: true,
         sort: true,
        }
       },
      {
        name: "ID",
        options: {
         filter: false,
         sort: false,
         display: false,
        }
       },
       {
        name: "Tax Status",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Grants Accepted",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Grants Pending",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Grants Tabled",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Grants Declined",
        options: {
         filter: false,
         sort: true,
        }
       },
     
     ];
     
    
     
     const options = {
       filterType: 'checkbox',
       print: false,
      download: false,
      selectableRows: false,
     };
     
    
    if(this.state.loading){
      return(<p>Loading...</p>)
    }else{
      return(
        <MUIDataTable
        title={"Organization List"}
        data={this.state.organizationsArray}
        columns={columns}
        options={options}
      />
      )
    }

  }
}

export default withStyles(styles)(OrganizationTable)

