import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    margin:  10,
    alignContent: "flex-end"
  },
  input: {
    display: 'none',
  },
});

function GrantStatusButtons(props) {
  const { classes } = props;
  return (
    <div>
    <Button variant="contained" className={classes.button} color="primary" onClick={props.handleViewChange}>{props.currentView === "organizations" ? "View Grants" : "View Organizations"}</Button>
    <Button variant="contained" className={classes.button} color="secondary" onClick={() => { props.handleFilterTypeChange("All")}}>AlL</Button>
    <Button variant="contained" className={classes.button} color="secondary" onClick={ () => { props.handleFilterTypeChange("Accepted")}}>Accepted : {props.accepted}</Button>
    <Button variant="contained" className={classes.button} color="secondary" onClick={ () => { props.handleFilterTypeChange("Pending")}}>Pending : {props.pending}</Button>
    <Button variant="contained" className={classes.button} color="secondary" onClick={ () => { props.handleFilterTypeChange("Tabled")}}>Tabled : {props.tabled}</Button>
    <Button variant="contained" className={classes.button} color="secondary" onClick={ () => { props.handleFilterTypeChange("Declined")}}>Declined : {props.declined}</Button>
    </div>
  );
}

export default withStyles(styles)(GrantStatusButtons);