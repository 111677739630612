import ReactDOM from 'react-dom';
import './index.css';
// import './common_styles/bootstrap.mixin.css';
import '.'
import { makeMainRoutes } from './components/routes';

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);