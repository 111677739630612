import React, { Component } from 'react'
import OrganizationTable from '../OrganizationTable';
import axios from 'axios';
import {API_URL} from '../constants'
import GrantStatusButtons from '../GrantStatusButtons'
import FullGrantTable from '../FullGrantTable'
import FilterByDate from '../FilterByDate'

export class OrganizationView extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      loading_buttons: true,
      pending_grants: null,
      accepted_grants: null,
      declined_grants: null,
      tabled_grants: null,
      currentView: "organizations",
      startDate: null,
      endDate: null,
      filter: null,
      orgFilterType: "All",
      grantFilterType: "All",

    }

    this.getTotals = this.getTotals.bind(this);
  }

  componentDidMount(){
    this.getTotals();
  }

  handleViewChange = () =>{
    if(this.state.currentView === "organizations"){
      this.setState({currentView: "grants"})
    }else{
      this.setState({currentView: "organizations"})      
    }
  }

  handleDateChange = (input, date) => {
    if(input === "start"){
      this.setState({startDate: date})
      console.log("date changed to ", this.state.startDate)
    }else if(input === "end"){
      this.setState({endDate: date})
    }
  }

  handleFilterSubmit = (start, end, type) => {
    this.setState({
      startDate: start,
      endDate: end,
      filter: true,
      searchType: type
    })
  }

  handleFilterTypeChange = (type) => {
    if(this.state.currentView === "organizations"){
    this.setState({orgFilterType: type});
    }else{
      this.setState({grantFilterType: type})
    }
  }

  formatDate(date) {
    let d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  getTotals = () => {
    axios.get(`${API_URL}/organization/totals`)
          .then((res) =>{
            let {approved, declined, pending, tabled} = res.data;
            this.setState({
              loading_buttons : false,
              pending_grants : Number(pending),
              accepted_grants : Number(approved),
              declined_grants : Number(declined),
              tabled_grants : Number(tabled),
            })
          })
  }

  render(){

    if(!this.state.loading_buttons){
    return(
      <React.Fragment>
        
        <GrantStatusButtons accepted={this.state.accepted_grants} declined={this.state.declined_grants} 
          pending={this.state.pending_grants} tabled={this.state.tabled_grants} currentView={this.state.currentView} 
          handleViewChange={this.handleViewChange} 
          handleFilterTypeChange={this.handleFilterTypeChange} />
        {this.state.currentView === "organizations" ? (<OrganizationTable auth={this.props.auth} orgFilterType={this.state.orgFilterType}/>) : (
          <React.Fragment>
            <FilterByDate handleDateChange={this.handleDateChange} handleFilterSubmit={this.handleFilterSubmit}/>
            <FullGrantTable auth={this.props.auth} filter={this.state.filter} startDate={this.state.startDate} 
              endDate={this.state.endDate} searchType={this.state.searchType} grantFilterType={this.state.grantFilterType}/>
          </React.Fragment>
        )}
      </React.Fragment>
      )}else{
        return(<OrganizationTable auth={this.props.auth}/>)
      }

  }
 
}

export default OrganizationView

