import React, { Component } from 'react';
import './App.css';
import OrganizationView from '../OrganizationView'
import NavBar from '../NavBar'
import Auth from '../Auth/Auth.js';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import axios from 'axios';

const theme = createMuiTheme({
  palette : {
            primary: { main: '#0D283C' },
          secondary: { main: '#E5BF78' },
  },
          typography: {
            // Use the system font instead of the default Roboto font.
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
            ].join(',')
}

});

class App extends Component {
  constructor(props){
    super(props);
    this.API_URL = "http://localhost:8080"
  }
  
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <MuiThemeProvider theme={theme}>
      <div className="main-container">
       <NavBar auth={this.props.auth} theme={theme} />
      </div>
       </MuiThemeProvider>
    );
  }
}

export default App;