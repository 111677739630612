import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// import '../OrganizationDetailBox.css';
import axios from 'axios';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import {API_URL} from '../constants'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const taxStatuses = [
  {
    value: 'for profit',
  },
  {
    value: 'non profit 501(c)3',
  },
  {
    value: 'non profit 501(c)4',
  },
  {
    value: 'non profit 501(c)5',
  },
  {
    value: 'non profit 501(c)6',
  },
  {
    value: 'government/municipality',
  },
];

const counties = [
  {
    value: 'Grayson County'
  },
  {
    value: 'Mayes County'
  }
];

class OutlinedTextFields extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      name: this.props.name,
      success:false,
      "county_id": this.props.county_id,
      "contact_name": this.props.contact_name,
      "contact_title": this.props.contact_title,
      "contact_number": this.props.contact_number,
      "mailing_address": this.props.mailing_address,
      "contact_fax": this.props.contact_fax,
      "contact_email": this.props.contact_email,
      "request_contact_name": this.props.request_contact_name,
      "request_contact_title": this.props.request_contact_title,
      "request_contact_number": this.props.request_contact_number,
      "request_mailing_address": this.props.request_mailing_address,
      "request_contact_email": this.props.request_contact_email,
      "tax_id": this.props.tax_id,
      "tax_status": this.props.tax_status,
      "website": this.props.website,
      "mission_statement": this.props.mission_statement,
    };

    this.saveData = this.saveData.bind(this);

  }
  
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  
  saveData = () => {
    let obj = {
      "name": this.state.name,
      "county_id": this.state.county_id,
      "contact_name": this.state.contact_name,
      "contact_title": this.state.contact_title,
      "contact_number": this.state.contact_number,
      "mailing_address": this.state.mailing_address,
      "contact_fax": this.state.contact_fax,
      "contact_email": this.state.contact_email,
      "request_contact_name": this.state.request_contact_name,
      "request_contact_title": this.state.request_contact_title,
      "request_contact_number": this.state.request_contact_number,
      "request_mailing_address": this.state.request_mailing_address,
      "request_contact_email": this.state.request_contact_email,
      "tax_id": this.state.tax_id,
      "tax_status": this.state.tax_status,
      "website": this.state.website,
      "mission_statement": this.state.mission_statement,
    }
    axios.put(`${API_URL}/organization/${this.props.id}`, obj)
              .then(() =>{
                this.props.handleStatusMessage(true)
              })
              .catch((err) => {
                console.log(err)
                this.props.handleStatusMessage(false)
              })
              .finally(() => {
                this.props.toggleEdit();
              })
  }
  render() {
    const { classes } = this.props;
    let smallView = this.props.smallView
    
    return (
      <Paper className={classes.root}>
      <Typography variant="h2">Edit Organization</Typography>
      <Divider />
      <form className={classes.container} noValidate autoComplete="off">
      <Button  variant="contained" color="primary" className={classes.button} onClick={this.saveData}>Save</Button>
      <Button variant="contained" onClick={this.props.toggleEdit}>Cancel</Button>
      <Grid container spacing={24}>
      <Grid item xs={12} sm={smallView} xl={smallView} zeroMinWidth>
      <List>       
      <ListItem><Typography variant="h4">Details</Typography></ListItem>
      <ListItem>
      <TextField
      id="outlined-name"
      label="Organization Name"
      className={classes.textField}
      value={this.state.name}
      defaultValue={this.state.name}
      onChange={this.handleChange('name')}
      margin="normal"
      variant="outlined"
      />
      <TextField
      id="outlined-mailing-address"
      label="Address"
      defaultValue={this.state.mailing_address}
      onChange={this.handleChange('mailing_address')}
      className={classes.textField}
      margin="normal"
      variant="outlined"
      />
      <TextField
      id="outlined-website"
      label="Website"
      defaultValue={this.state.website}
      onChange={this.handleChange('website')}
      className={classes.textField}
      margin="normal"
      variant="outlined"
      />
      
      <TextField
      id="outlined-select-county-id"
      select
      label="County"
      className={classes.textField}
      value={this.state.county_id}
      onChange={this.handleChange('county_id')}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="outlined"
      >
      {counties.map(option => (
        <MenuItem key={option.value} value={option.value}>
        {option.value}
        </MenuItem>
        ))}
        </TextField>
        
        <TextField
        id="outlined-select-tax-status"
        select
        label="Tax Status"
        className={classes.textField}
        value={this.state.tax_status}
        onChange={this.handleChange('tax_status')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        >
        {taxStatuses.map(option => (
          <MenuItem key={option.value} value={option.value}>
          {option.value}
          </MenuItem>
          ))}
          </TextField>
          <TextField
          id="outlined-tax_id"
          label="Tax Id"
          defaultValue={this.state.tax_id}
          onChange={this.handleChange('tax_id')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          />
          </ListItem>
          
          <ListItem><Typography variant="h4">Mission Statement</Typography></ListItem>
          <ListItem>     
            <TextField
            id="outlined-multiline-flexible"
            label="Mission Statement"
            multiline
            fullWidth
            rows="5"
            value={this.state.mission_statement}
            onChange={this.handleChange('mission_statement')}
            className={classes.textField}
            margin="normal"
            
            variant="outlined"
            />
          </ListItem>
          
          
          
          </List>
          
          </Grid>
          </Grid>
          </form>
          </Paper>
          );
        }
      }
      
      
      
      export default withStyles(styles)(OutlinedTextFields);