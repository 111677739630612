import { ROOT_URL } from '../constants'
let root = ROOT_URL;
if (process.env.NODE_ENV === 'staging' || process.env.REACT_APP_ENV === "staging"){
  console.log("Auth0 variables Staging")
  root = 'https://ogma-staging.herokuapp.com';
}else if(process.env.NODE_ENV === 'production'){
  root = 'https://app.mayorfoundation.org';
  console.log("Auth0 variables Production")
}

export const AUTH_CONFIG = {
  domain: 'ogma.auth0.com',
  clientId: '1uPwjAjjlrE32fpGqkXkVbClwRG3dRJR',
  callbackUrl: root + '/callback',
  apiUrl: 'ogma-app'
}
