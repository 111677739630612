import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import RaisedButton from '@material-ui/core/Button'
import {API_URL} from '../constants'
import axios from 'axios';
// import { ReactComponent as Logo } from './logo.svg';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  bigAvatar: {
    width: 200,
    height: 200,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class UploadPage extends React.Component{
  constructor() {
    super();
    this.state = {
      currentFile:null,
      currentPurpose: null,
      message: null
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);
  }
  
  handleSubmit(event){
    event.preventDefault();
    const data = new FormData();
    data.append('purpose', this.state.currentPurpose)  
    data.append('upload', this.state.selectedFile)  
    console.log("PATH NAME", this.props.location.pathname.slice(1));
    axios.post(`${API_URL}/${this.props.location.pathname.slice(1)}`, data)
    .then(res => {
      this.setState({message: "Upload Successful!"})
    })
    .catch(error => {console.log("ERROR fetching " + error);
    this.setState({message: "There was an error with your upload, please contact the Oliver Dewey Mayor Foundation"})
  })
  }
  

  fileChangedHandler = (event) => {
    this.setState({selectedFile: event.target.files[0]})
  }

  handlePurposeChange = (event) =>{
    this.setState({currentPurpose: event.target.value})
    console.log(this.state.currentPurpose)
  }
  
  render(){
    const { classes } = this.props;
    console.log(this.props.location.pathname)
    return (
      <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar
        alt="Oliver Dewey Mayor Foundation Logo"
        src="https://i.imgur.com/OkEKJJN.png"
        className={classNames(classes.avatar, classes.bigAvatar)}
        />
        {this.state.message ? <Typography variant="h3" color="secondary">{this.state.message}</Typography> : ''}
        <form className={classes.form} onSubmit={this.handleSubmit}>
        <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="title">Document Purpose</InputLabel>
        <Input id="title" name="purpose" autoComplete="Document Purpose" autoFocus onChange={this.handlePurposeChange}/>
        </FormControl>
        
        
        <input type="file" name="upload" id="upload" onChange={this.fileChangedHandler} />
        
        <Button
        
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={this.handleSubmit}
        >
        Submit
        </Button>
        </form>
      </Paper>
      </main>
      );
    }
  }
  
  export default withStyles(styles)(UploadPage);