import React, { Component } from 'react';
import './DetailGrantBox.css';
import axios from 'axios';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import {API_URL} from '../constants'
import {timeConverter} from '../helpers'
import GrantTextField from '../GrantTextField'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import { withTheme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Close from '@material-ui/icons/Close'
import Edit from '@material-ui/icons/Edit'
import Print from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Installements from '../Installments'
import Modal from '@material-ui/core/Modal';
import { Redirect, Router, Route, Link } from "react-router-dom";


const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    marginTop: 20,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  detailGrid:{
    flexGrow: 1,
    li : {
      padding: 0,
    }
  },
  listItem :{
    padding: 0
  },
  paper: {
    
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    itemTitle:{
      marginRight: 10,
      marginBottom:0,
      paddingBottom: 0
    },
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    marginRight: theme.spacing.unit
  },
  titleDivider: {
    margin: 20
  },
  lineBreak:{
    whiteSpace: "pre-line"
  },
  paragraph:{
  },
  itemTitle:{
    marginRight: 10,
    marginBottom:0,
  },
  link:{
    textDecoration: "none",
    marginBottom: 0
  },
   headline:{
    marginBottom: "10px"
  },
  header:{
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 0,
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '15%',
    left: '15%',
  },
  
});

class DetailGrantBox extends Component {
  constructor(props){
    super(props);
    this.org = {};
    this.state = {
      uploads: null,
      editView: null,
      statusMessage: '',
      deleteModal: false,
      redirect: false,
    }
    this.getUploads = this.getUploads.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteGrantRequest = this.deleteGrantRequest.bind(this);
  }
  componentDidMount(){
    // this.org = JSON.parse(this.props.organization);
    this.getUploads();
  }

  handleStatusMessage = (isSuccessful) => {
    if(isSuccessful){
      this.state.statusMessage = "Saved Successfully"
    }else{
      this.state.statusMessage = "Error Saving Data"
    }
  }

  toggleDeleteModal(){
    this.setState({deleteModal: !this.state.deleteModal})
  }

  deleteGrantRequest(){
    console.log("Delete called");
    axios.delete(`${API_URL}/grant/${this.props.grant.id}`)
    .then((res) => {
      this.props.closeSplitView();

      
      
      if(res.status === 200){
        this.state.statusMessage = "Grant Deleted Successfully"
      }else{
        this.state.statusMessage = "Error deleting grant"
      }
      this.toggleDeleteModal();
    })
  }
  
  getUploads(){
    axios.get(`${API_URL}/upload/${this.props.grant.id}`)
    .then((res) => this.setState({uploads: res.data}))
  }
  
  toggleEdit(){
    this.setState({editView: !this.state.editView})
    this.props.didEdit(this.props.grant.id);
  }

  handlePrint = async () => {
    await this.props.togglePrintView();
    window.print();
  }

  
  render() {
    const {classes, grant} = this.props
    let size = this.props.printView ? 6 : 12;
    if(!this.state.editView){
      return (
        <Paper className={classes.root}>
          {
            this.state.redirect ? <Redirect to="/org/" /> : ''
          }
        {this.state.deleteModal ? (<Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.deleteModal}
          onClose={this.toggleDeleteModal}
        >
          <div className={classes.modal}>
            <Typography variant="h4" id="modal-title">
             Delete the grant {grant.title}?
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              Warning: This will delete the grant and is not reversible. 
            </Typography>
            <Button variant="contained" className={classes.button} color="primary" onClick={this.deleteGrantRequest}>Yes</Button>
            <Button variant="contained"  className={classes.button} color="secondary"onClick={this.toggleDeleteModal}>Cancel</Button>
            
          </div>
        </Modal>) :''}
        <Typography variant="h2" component="h2" className={classes.headline}>{grant.title}</Typography>
        <Button variant="contained" className={classes.button} color="default" onClick={this.props.closeSplitView}><Close className={classes.leftIcon} />Close</Button>
        <Button variant="contained" className={classes.button}  color="primary" onClick={this.toggleEdit}><Edit className={classes.leftIcon} />Edit</Button>
        <Button variant="contained" className={classes.button}  color="primary" onClick={this.handlePrint}>
                                                                                        <Print className={classes.leftIcon} />Print</Button>
        <Button variant="contained" className={classes.button}  color="primary" onClick={this.toggleDeleteModal}>
                                                                                      <DeleteIcon className={classes.leftIcon} />Delete</Button>
                                                                                        
        <Divider></Divider>
        {this.state.statusMessage ? (<Typography variant="h4" component="h2" color="secondary">{this.state.statusMessage}</Typography>) :''}
        
        <Grid className={classes.detailGrid} container spacing={0}>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.header}><Typography variant="h4" component="h4">Primary Contact</Typography></ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Contact Name: </Typography>{grant.contact_name ? <p className={classes.paragraph}>{grant.contact_name}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Contact Title: </Typography>{grant.contact_title ? <p className={classes.paragraph}>{grant.contact_title}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Fax: </Typography>{grant.contact_fax ? <p className={classes.lineBreak}>{grant.contact_fax}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Phone: </Typography>{grant.contact_number ? <a href={"mailto:"+grant.contact_number}>{grant.contact_number}</a> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Email: </Typography>{grant.contact_email ? <a href={"mailto:"+grant.contact_email}>{grant.contact_email}</a> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.header}><Typography variant="h4" component="h4">Requestor Contact</Typography></ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Contact Name: </Typography>{grant.request_contact_name ? <p className={classes.lineBreak}>{grant.request_contact_name}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Contact Title: </Typography>{grant.request_contact_title ? <p className={classes.lineBreak}>{grant.request_contact_title}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Address: </Typography>{grant.request_mailing_address ? <p className={classes.lineBreak}>{grant.request_mailing_address}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Phone: </Typography>{grant.request_contact_number ? <a href={"mailto:"+grant.request_contact_number}>{grant.request_contact_number}</a> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Email: </Typography>{grant.request_contact_email ? <a href={"mailto:"+grant.request_contact_email}>{grant.request_contact_email}</a> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.header}><Typography variant="h4" component="h4">Details</Typography></ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Status: </Typography>{grant.disposition ? <p className={classes.lineBreak}>{grant.disposition}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Grant Amount: </Typography>{grant.grant_amount ? <p className={classes.lineBreak}>{grant.grant_amount}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Amount Requested: </Typography>{grant.requested_amount ? <p className={classes.lineBreak}>{grant.requested_amount}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Total Payed: </Typography>{grant.total_payed ? <p className={classes.lineBreak}>{grant.total_payed}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Date Payed: </Typography>{grant.date_paid ? <p className={classes.lineBreak}>{timeConverter(grant.date_paid)}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Date Submitted: </Typography>{grant.entry_date ? <p className={classes.lineBreak}>{timeConverter(grant.entry_date)}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Program Area: </Typography>{grant.program_area ? <p className={classes.lineBreak}>{grant.program_area}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Project Start: </Typography>{grant.project_start ? <p className={classes.lineBreak}>{timeConverter(grant.project_start)}</p> : ''}</ListItem> </Grid>
        <Grid item xs={6} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Project End: </Typography>{grant.project_end ? <p className={classes.lineBreak}>{timeConverter(grant.project_end)}</p> : ''}</ListItem> </Grid>
        <Grid item xs={12} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Project Description: </Typography>{grant.project_description ? <p className={classes.lineBreak}>{grant.project_description}</p> : ''}</ListItem> </Grid>
        <Grid item xs={12} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Previous Funding: </Typography>{grant.previous_grants ? <p className={classes.lineBreak}>{grant.previous_grants}</p> : ''}</ListItem> </Grid>
        <Grid item xs={12} sm={12} md={12}> <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Other Funding: </Typography>{grant.other_sources ? <p className={classes.lineBreak}>{grant.other_sources}</p> : ''}</ListItem> </Grid>
        </Grid>
        {grant.disposition === "Funding in installments" ? <Installements grant={grant} installment_total={grant.installment_total} individual_dispersment_amount={grant.individual_dispersment_amount}
                                                            dispersments_to_date={grant.dispersments_to_date} amount_dispersed_to_date={grant.amount_dispersed_to_date}/> : ''}
        <List>
       <div>
        <a href={`/upload/${grant.id}`} className={classes.link}><Button variant="contained" color="secondary" className={classes.button}>Upload<CloudUploadIcon className={classes.rightIcon} /></Button></a>
        <Typography variant="h6">Upload URL: {`${API_URL}/upload/${grant.id}`}</Typography>
        {this.state.uploads ? this.state.uploads.map((link, index) =>{
          return <ListItem key={index}><a href={link}>{link}</a></ListItem>
        }) : ''}
        {grant["csv_uploads"] ? grant["csv_uploads"].split(",").map((link, index) => {
            return <ListItem key={link}><a href={link} target="_blank">{link}</a></ListItem>
        }) : ''}
        {grant["initial_uploads"] ? JSON.parse(grant["initial_uploads"]).map((link, index) => {
            return <ListItem key={link}><a href={link} target="_blank">{link}</a></ListItem>
        }) : ''}
        </div>
        </List>
        
        </Paper>
        );
      }else{
        return(
          <GrantTextField {...this.props} toggleEdit={this.toggleEdit} handleStatusMessage={this.handleStatusMessage}/>
          );
        }
      }
    }
    
    export default withTheme()(withStyles(styles)(DetailGrantBox));