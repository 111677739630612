import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {secondaryColor} from '../colors'
import logo from '../../assets/Logo.png'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  
};
class NavBar extends Component {

  constructor(props){
    super(props)
    const { classes } = this.props;
    
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }  

  render(){
    const { isAuthenticated } = this.props.auth;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton className={this.props.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton> */}
              
              <Typography variant="h6" color="inherit" className={classes.grow}>
              <Link to="/app" style={{
                color: secondaryColor,
                textDecoration: "none"
              }}>
              {<img src={logo} />}</Link>
              </Typography>
            
            {
              !isAuthenticated() && (
                  <Button
                  color="secondary"
                    onClick={this.login.bind(this)}
                  >
                    Log In
                  </Button>
                )
            }
            {
              isAuthenticated() && (
                  <Button
                  color="secondary"
                    onClick={this.logout.bind(this)}
                  >
                    Log Out
                  </Button>
                )
            }
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(NavBar);