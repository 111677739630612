import React, { Component } from 'react';
import './OrganizationDetailBox.css';
import axios from 'axios';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import OrganizationTextField from '../OrganizationTextField'
import Edit from '@material-ui/icons/Edit'
import CallMerge from '@material-ui/icons/CallMerge'
import Icon from '@material-ui/core/Icon';
import {Link,Redirect} from 'react-router-dom'
import {API_URL} from '../constants'
import Modal from '@material-ui/core/Modal';



const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    marginTop: 20,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  orgLink:{
    "word-break": "break-all"
  },
  paper: {
  
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  titleDivider: {
    margin: 20
  },
  lineBreak:{
    whiteSpace: "pre-line",
    display: "inline-block",
    width: "100%"
  },
  itemTitle:{
    marginRight: 10
  },
  listItem:{
    paddingBottom: 0,
    paddingTop: 0
  },
  headline:{
    display: "inline-block"
  },
  nestedListItem:{
    padding: ".5rem .2rem"
  },
  button:{
    marginRight: ".5rem"
  },
  link:{
    textDecoration: "none"
  },
  buttonLink:{
    textDecoration: "none",
    display: "inline-block"
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '15%',
    left: '15%',
  },
  buttonGrid:{
    width:100

  },
 
});

class OrganizationDetailBox extends Component {
  constructor(props){
    super(props);
    this.org = {};
    this.state = {
      edit:null,
      statusMessage: '',
      deleteModal: false,
      redirect: false

    }
    this.toggleEdit = this.toggleEdit.bind(this);
    this.deleteOrg = this.deleteOrg.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);

  }
  componentDidMount(){
    // this.org = JSON.parse(this.props.organization);
  }


  toggleEdit(){
    this.setState({edit: !this.state.edit})
    this.props.didEdit();
  }

  toggleDeleteModal(){
    this.setState({deleteModal: !this.state.deleteModal})
  }
  

  deleteOrg(){
    const { getAccessToken } = this.props.auth;
    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    axios.put(`${API_URL}/organization/delete/${this.props.id}`)
          .then((res)=>{
            // if(res.status === 200){
            //   this.state.statusMessage = "Org Deleted Successfully"
            // }else{
            //   this.state.statusMessage = "Error deleting grant"
            // }
            this.setState({redirect: true})
          this.toggleDeleteModal();
            
          })
          .catch((error )=>{
            console.error("Error deleting organization", error)
          })


  }

  handleStatusMessage = (isSuccessful) => {
    if(isSuccessful){
      this.state.statusMessage = "Saved Successfully"
    }else{
      this.state.statusMessage = "Error Saving Data"
    }
  }
  
handleNewLine = (string, classes) => {  
    if(typeof string === "string"){

      return string.split("\\n").filter((el) => {
        return el.length > 1
      }).map((el) => {
        return (<ListItem className={classes.nestedListItem}>{el}</ListItem>);
      })
    
      
    }
    return;

  }
 

  render() {
    const {classes} = this.props
    let smallView = this.props.splitView ? 6 : 3;
    let smallTypography = this.props.splitView ? smallView + 4 : 9
    let smallButton = this.props.splitView ? smallView + 6 : 3;
    if(!this.state.edit){
      return (
        <div className=" organization-detail-box">   
          {
            this.state.redirect ? <Redirect to="/app" /> : ''
          }
        {this.state.deleteModal ? (<Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.deleteModal}
          onClose={this.toggleDeleteModal}
        >
          <div className={classes.modal}>
            <Typography variant="h4" id="modal-title">
             Delete the organization {this.props.name} ?
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              Warning: This will delete the organization and is not reversible. 
            </Typography>
            <Button variant="contained" className={classes.button} color="primary" onClick={this.deleteOrg}>Yes</Button>
            <Button variant="contained"  className={classes.button} color="secondary"onClick={this.toggleDeleteModal}>Cancel</Button>
            
          </div>
        </Modal>) :''}
      <Paper className={classes.root}>
      <Grid container spacing={24}>
        <Grid item xs={6} sm={smallTypography} xl={smallView+6} zeroMinWidth>
          <Typography variant="h2" component="h2" className={classes.headline}>{this.props.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={smallButton} xl={smallView} zeroMinWidth className="buttonGrid"> 
          <Button onClick={this.toggleDeleteModal} variant="contained" className={classes.button} color="primary"><Edit className={classes.leftIcon} />Delete</Button>
          <Button onClick={this.toggleEdit} variant="contained" className={classes.button} color="primary"><Edit className={classes.leftIcon} />Edit</Button>
          <Link className={classes.buttonLink} to={{pathname: `/merge/${this.props.id}`,
                    state: {organizationName: this.props.name}}}
          ><Button onClick={this.openMerge} variant="contained" className={classes.button} color="primary"><CallMerge className={classes.leftIcon} />Merge</Button></Link>
        </Grid>
        
      </Grid>
      <Divider className={classes.titleDivider}/>
      {this.state.statusMessage ? (<Typography variant="h4" component="h2" color="secondary">{this.state.statusMessage}</Typography>) :''}      
        <Grid container spacing={24}>
          <Grid item xs={6} sm={12} xl={smallView} zeroMinWidth>
            <List component="nav">
              <ListItem className={classes.listItem}><Typography variant="h4" component="h4">Details</Typography></ListItem>
              <ListItem className={classes.listItem}> <Typography variant="h6" className={classes.itemTitle}>Address: </Typography>{this.props.mailing_address ? <List>{this.handleNewLine(this.props.mailing_address, classes)}</List> : ''}</ListItem>
              <ListItem className={classes.listItem}> <Typography variant="h6" className={classes.itemTitle}>Website:</Typography>{this.props.website ? <a className={classes.orgLink} href={this.props.website}>{this.props.website}</a> : ''}</ListItem>
              <ListItem className={classes.listItem}> <Typography variant="h6" className={classes.itemTitle}>County:</Typography>{this.props.county_id ? <p>{this.props.county_id}</p> : ''}</ListItem>
              <ListItem className={classes.listItem}> <Typography variant="h6" className={classes.itemTitle}>Tax Status:</Typography>{this.props.tax_status ? <p>{this.props.tax_status}</p> : ''}</ListItem>
              <ListItem className={classes.listItem}> <Typography variant="h6" className={classes.itemTitle}>Tax ID:</Typography>{this.props.tax_id ? <p>{this.props.tax_id}</p> : ''}</ListItem>
           </List>
           
          </Grid>
          {/* <Grid item xs={6} sm={smallView} xl={smallView} zeroMinWidth>
            <List>
              <ListItem className={classes.listItem}> <Typography variant="h4" component="h4">Primary Contact</Typography></ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Name:</Typography>{this.props.contact_name ? <p>{this.props.contact_name}</p> : ''}</ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Email:</Typography>{this.props.contact_email ? <a href={"mailto:"+this.props.contact_email}>{this.props.contact_email}</a> : ''}<br /></ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Phone:</Typography>{this.props.contact_number ? <a href={"mailto:"+this.props.contact_number}>{this.props.contact_number}</a> : ''}</ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Fax:</Typography>{this.props.contact_fax ? <p>{this.props.contact_fax}</p> : ''}</ListItem>
            </List>
          </Grid> */}
          {/* <Grid item xs={6} sm={smallView} xl={smallView} zeroMinWidth>
            <List>
              <ListItem className={classes.listItem}> <Typography variant="h4" component="h4">Requested Contact</Typography></ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Name:</Typography>{this.props.request_contact_name ? <p>{this.props.request_contact_name}</p> : ''}</ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Email:</Typography>{this.props.request_contact_email ? <a href={"mailto:"+this.props.request_contact_email}>{this.props.request_contact_email}</a> : ''}<br /></ListItem>
              <ListItem className={classes.listItem}><Typography variant="h6" className={classes.itemTitle}>Phone:</Typography>{this.props.request_contact_number ? <a href={"tel:"+this.props.request_contact_number}>{this.props.request_contact_number}</a> : ''}</ListItem>
            </List>
          </Grid> */}
          <Grid item xs={6} sm={12} xl={smallView} zeroMinWidth>
            <List>
            <ListItem className={classes.listItem}> <Typography variant="h4" component="h4">Mission Statement</Typography></ListItem>
              <ListItem className={classes.lineBreak}>{this.props.mission_statement}</ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
        
      </div>
    );
  }else{
    return(
      <OrganizationTextField {...this.props} sm={smallView} xl={smallView} toggleEdit={this.toggleEdit} handleStatusMessage={this.handleStatusMessage}/>
    )
  }
  }
}

export default withStyles(styles)(OrganizationDetailBox);