import React from "react";
import DatePicker from "react-datepicker";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider'



import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const months = [
  {
    value: '01',
    label: 'January',
  },
  {
    value: '02',
    label: 'Febuary',
  },
  {
    value: '03',
    label: 'March',
  },
  {
    value: '04',
    label: 'April',
  },
  {
    value: '05',
    label: 'May',
  },
  {
    value: '06',
    label: 'June',
  },
  {
    value: '07',
    label: 'July',
  },
  {
    value: '08',
    label: 'August',
  },
  {
    value: '09',
    label: 'September',
  },
  {
    value: '10',
    label: 'October',
  },
  {
    value: '11',
    label: 'November',
  },
  {
    value: '12',
    label: 'December',
  },
  
];

class FilterByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      startMonth: '',
      startDay: '',
      startYear: '',
      endMonth: '',
      endDay: '',
      endYear: '',
    };
  }

  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  }; 

  handleSubmit = (type) => {
    let startDay = this.state.startDay.length === 1 ? "0" + this.state.startDay : this.state.startDay;
    let startYear = this.state.startYear.length === 2 ? "20" + this.state.startYear : this.state.startYear;
    let endDay = this.state.endDay.length === 1 ? "0" + this.state.endDay : this.state.endDay;
    let endYear = this.state.endYear.length === 2 ? "20" + this.state.endYear : this.state.endYear;
    let start = `${startYear}-${this.state.startMonth}-${startDay}`
    let end = `${endYear}-${this.state.endMonth}-${endDay}`
    // this.setState(
    // {
    //   startDate: `${this.state.startYear}/${this.state.startMonth}/${this.state.startDay}`,
    //   endDate: `${this.state.endDateYear}/${this.state.endDateMonth}/${this.state.endDateDay }`
    // }
    // )
    this.props.handleFilterSubmit(start, end, type)
  }

  render() {
  const { classes } = this.props;

   
  return (
    <div>
      <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Additional Filter Options</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <TextField
    id="startMonth"
    select
    label="Start Month"
    className={classes.textField}
    value={this.state.startMonth}
    onChange={this.handleChange('startMonth')}
    SelectProps={{
      MenuProps: {
        className: classes.menu,
      },
    }}
    margin="normal"
  >
    {months.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
  
  <TextField
          id="startDay"
          label="Start Day"
          className={classes.textField}
          value={this.state.startDay}
          onChange={this.handleChange('startDay')}
          margin="normal"
        /> 

    <TextField
          id="startYear"
          label="Start Year"
          className={classes.textField}
          value={this.state.startYear}
          onChange={this.handleChange('startYear')}
          margin="normal"
        /> 
    </ExpansionPanelDetails>

    <ExpansionPanelDetails>
    <TextField
    id="endMonth"
    select
    label=" End Month"
    className={classes.textField}
    value={this.state.endMonth}
    onChange={this.handleChange('endMonth')}
    SelectProps={{
      MenuProps: {
        className: classes.menu,
      },
    }}
    margin="normal"
  >
    {months.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
  
  <TextField
          id="endDay"
          label="End Day"
          className={classes.textField}
          value={this.state.endDay}
          onChange={this.handleChange('endDay')}
          margin="normal"
        /> 

    <TextField
          id="endYear"
          label="End Year"
          className={classes.textField}
          value={this.state.endYear}
          onChange={this.handleChange('endYear')}
          margin="normal"
        />
        </ExpansionPanelDetails> 
        <ExpansionPanelDetails>
    <Button variant="contained" className={classes.button} color="primary" onClick={() => {this.handleSubmit("paid") }}>Filter by Date Paid</Button>
    <Button variant="contained" className={classes.button} color="primary" onClick={() => {this.handleSubmit("submitted") }}>Filter by Date Submitted</Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
    

    </div>

  );
  }
}

export default withStyles(styles)(FilterByDate);
